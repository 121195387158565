<template>
    <div :class="{ overview: true, inactive: cardActive }">
        <p>Tik een lot aan voor meer informatie!</p>
        <div v-for="card of scratchcards" :key="card.id" :class="{ card: true, [ 'card' + card.id]: true }" @touchstart="open( card )" @pointerup="open( card )">
            <img :src="card.coverVisual" alt="" />
            <div class="tag">€ {{ card.price }}</div>
        </div>
        <p class="cta">Koop je Kraslot bij de kassa</p>
    </div>
</template>

<style lang="scss">
    // Unimportant css code, can be removed at will:
</style>

<script>
    import gsap from 'gsap';

    export default {
        name: 'Landing',

        computed: {
            scratchcards()
            {
                return this.$store.getters.scratchcards;
            },
            cardActive()
            {
                return this.$store.getters['card'];
            }
        },

        watch: {
            cardActive( value )
            {
                if( value )
                {
                    this.removeCard();
                }
                else
                {
                    this.restoreCard();
                }
            }
        },

        mounted()
        {
        },

        methods: {
            getAnimateIn()
            {
                let tl = gsap.timeline();
                tl.from( '.card', 0.4, { stagger: 0.05, scale: 0, rotation: function() { return ( Math.random() * 100 ) - 25; }, ease: 'back.out' });
                tl.from( '.card .tag', 0.4, { stagger: 0.05, autoAlpha: 0, scaleY: 0, ease: 'sine.out' }, '-=0.5' );
            },
            open( card )
            {
                window.plausible( 'Open on ' + window._rootData.screenName, { props: { card: card.name } });
                this.$store.dispatch( 'showCard', card );
            },
            removeCard()
            {
                console.log( 'remove' );
                let tl = gsap.timeline();
                tl.to( '.card' + this.cardActive.id, 0.4, { scale: 1.4, autoAlpha: 0, ease: 'sine.out' });
            },
            restoreCard()
            {
                console.log( 'restore' );
                let tl = gsap.timeline();
                tl.to( '.card', 0.4, { scale: 1, autoAlpha: 1, ease: 'sine.out' });
            }
        }
    };
</script>
